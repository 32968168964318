import * as React from 'react';
import type { HeadFC } from 'gatsby';
import { graphql, Link } from 'gatsby';
import FooterLinks from '../components/FooterLinks';
import config from '../../gatsby-config';
import Header from '../components/Header';

type Props = {
  data: {
    allMarkdownRemark: {
      nodes: Array<any>;
    };
  };
};

const IndexPage = ({
  data: {
    allMarkdownRemark: { nodes },
  },
}: Props) => (
  <main>
    <div className="intro">
      <h1>{config.siteMetadata.title}</h1>
      <p>Just some stuff I learnt along the way...</p>
      <div>
        <Link to="/bookshelf">Bookshelf</Link>
        {/*<sup>[New!]</sup>*/}
      </div>
    </div>
    <div className="post-list">
      {nodes.map(
        ({
          id,
          frontmatter: { date, slug, title, subtitle },
          fields: {
            readingTime: { text },
          },
        }) => (
          <div key={id} className="post">
            <Link to={slug}>
              <h2 className="title">{title}</h2>
            </Link>
            <p className="date">{date}</p>
            <p className="reading">{text}</p>
            <p className="subtitle">{subtitle}</p>
          </div>
        )
      )}
    </div>
    <FooterLinks />
  </main>
);

export default IndexPage;

export const Head: HeadFC = () => <Header />;

export const pageQuery = graphql`
  query MyQuery {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { slug: { nin: ["/bookshelf"] } } }
    ) {
      nodes {
        id
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          slug
          title
          subtitle
        }
        fields {
          readingTime {
            text
          }
        }
      }
    }
  }
`;
